<template>
  <div class="conBox" v-if="show">
    <div class="backgrBox dip fdcolmun">
      <h2>{{ $fanyi("你准备好开始导入了吗?") }}</h2>
      <p>
        {{
          $fanyi(
            "加入我们由数千名进口商组成的社区，并开始使用Rakumart智能进口!"
          )
        }}
      </p>
      <el-button
        class="fontBlueBtn register"
        @click="$fun.routerToPage('/register')"
        >{{ $fanyi("注册") }}</el-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: !localStorage.getItem("user_token"),
    };
  },
  components: {},
  computed: {},
  watch: {
    "$store.state.userInfo"(onew, old) {
      console.log(!!onew);
      if (!onew) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.conBox {
  background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d2e79928f3f.jpg");
  height: 424px;
  .backgrBox {
    height: 100%;

    background-image: linear-gradient(
      0deg,
      rgba(26, 115, 232, 0.75) 0%,
      rgba(26, 115, 232, 0.75) 100%
    );
    color: var(--Base-White, #fff);
    h2 {
      font-size: 36px;
      font-weight: 700;
      line-height: 44px; /* 122.222% */
      letter-spacing: -0.72px;
      margin-bottom: 20px;
    }
    p {
      font-size: 20px;
      line-height: 30px; /* 150% */
      margin-bottom: 36px;
    }
    .register {
      width: 292px;
      height: 60px;
      padding: 16px 22px;
      font-size: 18px;
      font-weight: 700;
    }
  }
}
</style>
