<template>
  <div class="conBox">
    <h2>{{ $fanyi("严选工厂") }}</h2>
    <div class="shopList flex jcenter flw">
      <div
        class="shopItem"
        v-for="(shopItem, shopIndex) in lists"
        :key="shopIndex"
      >
        <!-- 商品名和收藏按钮 -->
        <div class="shopName flex acenter">
          <span
            @click="
              $fun.routerToPage(
                `/shopGoods?shopName=${shopItem.shop_name_translate}&shopId=${shopItem.shop_id}`,
                true
              )
            "
            >{{ shopItem.shop_name_translate }}</span
          >
          <button
            class="fontBlueBtn favoShopBtn"
            v-if="shopItem.shopFavoriteStatus"
            @click="favoriteShopDelete(shopItem)"
          >
            {{ $fanyi("取消追踪") }}
          </button>
          <button
            class="bgBlueBtn favoShopBtn"
            v-else
            @click="shopAddFavorite(shopItem)"
          >
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d2c021ac9a1.svg"
              alt=""
            />{{ $fanyi("收藏(简短)") }}
          </button>
        </div>

        <div class="flex">
          <!-- 评分 -->
          <div class="rateBox">
            <div class="rateContainerHead flex acenter">
              <div class="text">{{ $fanyi("品质保证") }}</div>
              <el-popover
                popper-class="rateBoxPopover"
                placement="bottom"
                width="309"
                trigger="hover"
                @show="
                  shopItem.hoverTip = true;
                  lists.splice(0, 0);
                "
                @hide="
                  shopItem.hoverTip = false;
                  lists.splice(0, 0);
                "
              >
                <div class="popoverRateBox">
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("质量评分") }}</div>
                    <el-rate
                      v-model="shopItem.sellerDataInfo.offerExperienceScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("物流评分") }}</div>
                    <el-rate
                      v-model="shopItem.sellerDataInfo.logisticsExperienceScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("问题解决评分") }}</div>
                    <el-rate
                      v-model="shopItem.sellerDataInfo.disputeComplaintScore"
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                  <div class="rateContainer">
                    <div class="text">{{ $fanyi("咨询评分") }}</div>
                    <el-rate
                      v-model="
                        shopItem.sellerDataInfo.consultingExperienceScore
                      "
                      disabled
                      text-color="#ff9900"
                    >
                    </el-rate>
                  </div>
                </div>
                <div slot="reference" class="flex acenter">
                  <el-rate
                    v-model="shopItem.sellerDataInfo.compositeServiceScore"
                    disabled
                    text-color="#ff9900"
                  >
                  </el-rate>
                  <img
                    class="rateIcon"
                    :class="{ hoverIn: shopItem.hoverTip }"
                    src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
                    alt=""
                  />
                </div>
              </el-popover>
            </div>
          </div>
          <div class="sellerTagsBar flex acenter">
            <!-- 实力商家 -->
            <div
              class="iconBox blue"
              v-if="haveShopsTag('powerful_merchants', shopItem)"
            >
              <img :src="require('@/assets/icon/goods1.svg')" alt="" />
              <span>{{ $fanyi("实力商家") }}</span>
            </div>
            <!-- 超级工厂 -->
            <div
              class="iconBox red"
              v-if="haveShopsTag('super_factory', shopItem)"
            >
              <img :src="require('@/assets/icon/goods2.svg')" alt="" />
              <span>{{ $fanyi("超级工厂") }}</span>
            </div>
          </div>
        </div>

        <!-- top商品列表 -->
        <div class="topGoodsList flex jsb">
          <div
            class="shopTopGoodsItem"
            v-for="(goodsItem, goodsIndex) in shopItem.shopGoodTop"
            :key="goodsIndex"
            @click="
              $fun.routerToPage(
                '/ProductDetails?goods_id=' + goodsItem.goodsId,
                true
              )
            "
          >
            <div class="topGoodsImg">
              <img :src="goodsItem.imgUrl" alt="" />
            </div>
            <div class="topTip">TOP {{ goodsIndex + 1 }}</div>
          </div>
          <!-- <div
            class="topGoodsItem"
            v-for="(goodsItem, goodsIndex) in shopItem.shopGoodTop"
            :key="goodsIndex"
          >
            <el-image
              :src="goodsItem.imgUrl"
              lazy
              @click="
                $fun.routerToPage(
                  '/ProductDetails?goods_id=' + goodsItem.goodsId,
                  true
                )
              "
            >
            </el-image>
            <div class="topTip">TOP {{ hotIndex + 1 }}</div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lists: [],
    };
  },
  components: {},
  computed: {},
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.getHomeShopList().then((res) => {
        ////console.log('事件名',res)
        if (res.code != 0)
          return this.$message.error(this.$fanyi(res.data.msg));
        //接下来的操作
        // 把评分的数据转化为数值型;
        let shop_id = [];
        this.lists = [];
        res.data.shop_list.forEach((element) => {
          shop_id.push(element.shop_id);
          for (let i in element.sellerDataInfo) {
            element.sellerDataInfo[i] = Number(element.sellerDataInfo[i] || 0);
          }
        });
        this.lists = res.data.shop_list;
        if (localStorage.getItem("user_token")) {
          this.getCollection(JSON.stringify(shop_id));
        }
      });
    },

    // 判断显示商家标签
    haveShopsTag(in_name, shopItem) {
      let offerIdentities = shopItem.offerIdentities;
      if (!offerIdentities) return false;
      if (offerIdentities.find((ii) => ii == in_name)) return true;
      return false;
    },

    // 获取列表中店铺是否收藏
    getCollection(shop_id) {
      this.$api
        .favoriteShopCheckIn({
          shop_id,
          shop_type: 1688,
        })
        .then((res) => {
          ////console.log('事件名',res)
          if (res.code != 0) return false;
          //接下来的操作
          console.log(this.lists);
          this.lists.forEach((element, index) => {
            console.log(res.data[index].inFavorite);
            element.shopFavoriteStatus = res.data[index].inFavorite;
          });
        });
    },
    // 店铺收藏移除
    favoriteShopDelete(shopItem) {
      this.$api
        .favoriteShopDelete({
          shop_type: 1688,
          shop_name: shopItem.shop_name_translate,
          shop_id: shopItem.shop_id,
          wangwang: shopItem.wangwang,
        })
        .then((res) => {
          if (res.code != 0) return false;
          shopItem.shopFavoriteStatus = !shopItem.shopFavoriteStatus;
          this.$forceUpdate();
        });
    },
    // 将店铺加入收藏夹
    shopAddFavorite(shopItem) {
      if (!this.$store.state.userInfo) {
        return this.$store.commit(
          "getshowLoginDialog",
          !this.$store.state.showLoginDialog
        );
      }
      this.$api
        .favoriteShopAdd({
          shop_type: 1688,
          shop_name: shopItem.shop_name_translate,
          shop_id: shopItem.shop_id,
          wangwang: shopItem.wangwang,
        })
        .then((res) => {
          if (res.code != 0) return false;
          shopItem.shopFavoriteStatus = !shopItem.shopFavoriteStatus;
          this.$forceUpdate();
        });
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.conBox {
  max-width: 1480px;
  min-width: 750px;
  margin: 0 auto;
  padding: 0 20px 40px;
  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    margin-bottom: 24px;
  }
  .shopList {
    gap: 24px;
    .shopItem {
      width: 700px;
      height: 250px;
      padding: 16px;
      border-radius: 16px;
      border: 1px solid var(--Gray-100, #f2f4f7);
      background: #fff;
      /* Shadows/shadow-sm */
      box-shadow: 0px 1px 3px 0px rgba(16, 24, 40, 0.1),
        0px 1px 2px 0px rgba(16, 24, 40, 0.06);
     

      .shopName {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px; /* 133.333% */
        margin-bottom: 16px;
        cursor: pointer;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 80%;
        }
        .favoShopBtn {
          margin-left: auto;
          padding: 0px 12px;
          height: 36px;
          @extend .dip;
          img {
            margin-right: 4px;
          }
          /deep/span {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .rateBox {
        .rateContainerHead {
          width: max-content;
          cursor: pointer;
          .text {
            margin-right: 15px;
          }
          .rateIcon {
            transition: 0.3s;
            rotate: 180deg;
          }
          .rateIcon.hoverIn {
            rotate: 0deg;
          }
        }
      }
      .topGoodsList {
        margin-top: 16px;
        .shopTopGoodsItem {
          width: 125px;
          height: 125px;
          border-radius: 16px;
          overflow: hidden;
          cursor: pointer;

          position: relative;
          transition: 0.3s;
          .topGoodsImg {
            width: 100%;
            height: 100%;
            border-radius: 16px;
            background: #d9d9d9;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .topTip {
            transition: 0.3s;
            border-radius: 8px;
            background: var(--Rakumart-500, #1a73e8);
            width: 80px;
            height: 22px;
            color: var(--Base-White, #fff);
            text-align: center;
            font-size: 14.366px;
            font-weight: 600;
            line-height: 22px;
            position: absolute;
            bottom: 17px;
            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto;
          }
        }
        .shopTopGoodsItem:hover {
          transform: translate(0, -5px);
          box-shadow: 0px 10px 20px #d1d1d1;
          .topTip {
            bottom: 24px;
          }
        }
      }
    }
  }
}

/deep/.el-rate__icon {
  font-size: 24px;
  margin: -2px -3px; //模拟西班牙设计图星星的大小
  margin-right: 4px;
}
.popoverRateBox {
  padding: 12px;
  .rateContainer {
    margin-bottom: 8px;
    .text {
      color: var(--Gray-700, #344054);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      display: flex;
      align-items: center;
      height: 20px;
    }
  }
}

.iconBox {
  display: flex;
  align-items: center;
  padding: 2px 6px;
  border: solid 1px;
  border-radius: 6px;
  margin-right: 16px;
  img {
    width: 12px;
    margin-right: 4px;
  }
  span {
    font-size: 12px;
    font-weight: 600;
    line-height: 22px;
  }
}
.iconBox.red {
  color: #b93815;
  border-color: #f9dbaf;
  background: #fef6ee;
}
.iconBox.blue {
  color: #5925dc;
  border-color: #5925dc;
  background: #f4f3ff;
}
.sellerTagsBar {
  margin-left: auto;
}
</style>
