<template>
  <div id="homePageCon">
    <homePageTop />
    <!-- 网站简介视频弹窗 -->
    <beginnerTipsDia ref="BeginnerTipsDia" />
    <!-- 商品搜索 -->
    <productSearch />
    <!-- 著名供应商 -->
    <famousSupplier />
    <!-- 著名产品 -->
    <famousProducts />
    <!-- 注册邀请 -->
    <registrationInvitation />
    <Foot />
  </div>
</template>

<script>
import beginnerTipsDia from "../../components/homePage/beginnerTipsDia.vue";
import homePageTop from "../../components/head/HomePageTop.vue";
import productSearch from "../../components/homePage/productSearch.vue";
import famousSupplier from "../../components/homePage/famousSupplier.vue";
import famousProducts from "../../components/homePage/famousProducts.vue";
import registrationInvitation from "../../components/homePage/registrationInvitation.vue";
import Foot from "../../components/foot/Foot.vue";
export default {
  data() {
    return {};
  },
  components: {
    homePageTop,
    Foot,
    beginnerTipsDia,
    productSearch,
    famousSupplier,
    famousProducts,
    registrationInvitation,
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
#homePageCon {
  background-color: white;
  min-width: 1470px;
}
</style>
