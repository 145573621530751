<template>
  <div class="searchBgBox">
    <div class="searchBox">
      <h3>{{ $fanyi("介意聪明") }}</h3>
      <h1>{{ $fanyi("从中国进口最简单、最安全的方式") }}</h1>
      <transition name="opacity-slide">
        <div class="upLoadShadow" v-show="showUpModel == 'loading'"></div>
      </transition>

      <!-- 粘贴上传图片 -->
      <el-upload
        class="upImg dip"
        drag
        action="https://laravel.rakumart.es/api/common/uploadFile"
        :before-upload="befoUpload"
        :on-success="successup"
        :http-request="httpup"
        accept=".jpg,.jpeg,.png"
        :show-file-list="false"
        :class="{ readOnly: showUpModel == 'loading' }"
      >
        <div
          class="dip upCon"
          :class="{
            bgBlueBtn: showUpModel == 'btn',
            upLoadBox: ['box', 'loading'].includes(showUpModel),
          }"
        >
          <!-- 关闭图片搜索模块按钮 -->
          <img
            v-if="showUpModel == 'box'"
            class="closeBtn"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66d515d9b603c.svg"
            alt=""
            @click.stop="showUpModel = 'btn'"
          />

          <!-- 图片搜索模块 -->
          <div class="dip fileNormalBox" v-if="showUpModel == 'box'">
            <div class="upIconBox">
              <img
                class="upIconShow"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66d81bb157573.png"
                alt=""
              />
              <img
                class="blueImg"
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d13c676eb5c.svg"
                alt=""
              />
            </div>
            <span>Arrastra la imagen o&nbsp;</span>
            <span class="blue">súbela desde tus archivos</span>
          </div>

          <!-- 用户拖入图片模块 -->
          <div class="fileHoverBox" v-if="showUpModel == 'box'">
            {{ $fanyi("在这里发布图片") }}
          </div>

          <!-- 图片上传中模块 -->
          <div
            class="fileLoadingBox dip fdcolmun"
            v-if="showUpModel == 'loading'"
          >
            <div class="loadingIcon" v-loading="true"></div>
            <div class="tipBox">
              {{ $fanyi("我们正在寻找您的产品，并为您选择最好的供应商。") }}
            </div>
            <div class="smallTipBox">
              {{ $fanyi("请稍等。") }}
            </div>
          </div>

          <!-- 按钮模块 -->
          <div
            v-if="showUpModel == 'btn'"
            class="dip"
            style="width:100%;height:100%"
            @click.stop="showUpModel = 'box'"
          >
            <img
              class="upIcon"
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202409/66d51743c22b7.png"
              alt=""
            />
            <span>{{ $fanyi("按图片搜索产品(推荐)") }}</span>
          </div>
        </div>
      </el-upload>
      <!-- 文字搜索 -->
      <div class="textSearchBox">
        <div class="searchTitle">
          {{ $fanyi("或者你可以搜索超过1亿种产品:") }}
        </div>
        <div class="dip" style="margin-bottom: 24px;">
          <div class="inputBox dip">
            <div class="iconBox dip">
              <img
                src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d142e95c4c4.svg"
                alt=""
              />
            </div>
            <div
              class="placeholderBox"
              v-if="!$store.state.searchInfo && showPlaceHold"
            >
              Pega aquí la URL del producto de
              <b>1688.com</b>
            </div>
            <input
              type="text"
              v-model="$store.state.searchInfo"
              @keydown.enter="search"
              @input="oninputMe"
            />
          </div>
          <button class="bgBlueBtn searchBtn dip" @click="search">
            <img
              src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d157f975e83.svg"
              alt=""
            />
            <span>{{ $fanyi("搜索") }}</span>
          </button>
        </div>
        <div class="union">
          Partner oficial de
          <b
            class="orange"
            style=" cursor: pointer;"
            @click="$fun.routerToPage('https://www.1688.com', true)"
            >1688.com</b
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";
export default {
  data() {
    return {
      showUpModel: "btn", //图片上传框按钮或者上传框切换显示状态 btn/box/loading
      showPlaceHold: true, //输入框显示placehold控制
    };
  },
  components: {},
  computed: {},
  created() {},
  methods: {
    // 跳转搜索页
    search() {
      let regtaobao = RegExp(/taobao/);
      let reg1688 = RegExp(/1688/);
      let regtmall = RegExp(/tmall/);
      let regrakumart = RegExp(/goods_id/);
      let id = "";
      var reg1 = /1688.com\/offer\/([0-9]+)/;
      var reg4 = /m\.1688\.com.*offerId=([0-9]{10,})/; //1688手机
      var reg2 = /[?|&]id=(\d{10,15})/;
      var reg3 = /[?|&]goods_id=(\d{10,15})/;
      // 判断是哪个网站的商品链接
      if (regtaobao.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "taobao");
        return false;
      } else if (
        reg1688.test(this.$store.state.searchInfo) &&
        (this.$store.state.searchInfo.indexOf("offer") != -1 ||
          this.$store.state.searchInfo.indexOf("offerId") != -1)
      ) {
        if (this.$store.state.searchInfo.match(reg1) == null) {
          id = this.$store.state.searchInfo.match(reg4)[1];
        } else {
          id = this.$store.state.searchInfo.match(reg1)[1];
        }
        this.$fun.toCommodityDetails(id, "1688");
        return false;
      } else if (regtmall.test(this.$store.state.searchInfo)) {
        id = this.$store.state.searchInfo.match(reg2)[1];
        this.$fun.toCommodityDetails(id, "tmall");
        return false;
      } else if (regrakumart.test(this.$store.state.searchInfo)) {
        // return false;
        id = this.$store.state.searchInfo.match(reg3)[1];
        this.$fun.toCommodityDetails(id, "tmall");
      }
      // --------------------------------------------------
      // 设置分类选择框的已选中id
      let selId = this.value
        ? this.options.find((item) => item.chinese_name == this.value).id
        : "";
      // 如果是商品搜索页面并且路由上的id和选择器上的id不一致就重设路由
      if (
        this.$route.name == "CommoditySearch" &&
        selId != this.$route.query.group_id
      ) {
        //console.log("重设路由");
        this.$fun.routerToPage({
          query: {
            keyword: this.$store.state.searchInfo,
          },
        });
      }
      // 如果搜索的是同一词条就刷新页面
      if (this.$store.state.searchInfo == this.$route.query.keyword) {
        this.$router.go(0);
        return false;
      }
      // 如果关键词为空就不跳转
      if (!this.$store.state.searchInfo) {
        this.$message(this.$fanyi("请输入关键词搜索"));
        return false;
      }
      // 跳转
      this.$fun.routerToPage(
        "/CommoditySearch?keyword=" +
          this.$store.state.searchInfo +
          "&group_id=" +
          selId,
        true
      );
    },

    befoUpload(file) {
      const isJPG =
        ["image/jpeg", "image/png", "image/jpg"].indexOf(file.type) != -1;
      //console.log(file.type);
      if (!isJPG) {
        this.$message.error(this.$fanyi("请上传图片"));
        return isJPG;
      }

      if (this.showUpModel == "loading") {
        return false;
      }

      this.showUpModel = "loading";
      // this.pageLoading = this.$loading({
      //   lock: true,
      //   text: this.$fanyi("图片上传中"),
      //   spinner: "el-icon-loading",
      //   background: "rgba(255, 255, 255, .7)",
      // });
    },
    blobToBase64(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    // 图片上传事件
    httpup(file) {
      var _this = this;
      this.imageId = "";
      this.tipagedata = "";
      new Compressor(file.file, {
        quality: 0.2,
        success(result) {
          // console.log("skdfh", result); // 处理后的文件流BLOB
          // 以下为文件本地保存、下载查看压缩后的文件效果和大小，可省略
          // const blobUrl = window.URL.createObjectURL(result);
          // const a = document.createElement("a");
          // a.download = result.name;
          // a.style.display = "none";
          // a.href = blobUrl;
          // // 触发点击
          // document.body.appendChild(a);
          // a.click();
          // // 然后移除
          // document.body.removeChild(a);
          // 以下为将文件加载到formData中以便文件上传

          _this.blobToBase64(result).then((reader) => {
            _this.getImageId(reader);
          });
          let formData = new FormData();
          formData.append("file", result);
          //文件上传请求
          var xhr = new XMLHttpRequest();
          xhr.open(
            "POST",
            "https://laravel.rakumart.es/api/common/uploadFile",
            true
          );
          xhr.onload = () => {
            if (xhr.status === 200) {
              const res = JSON.parse(xhr.responseText);
              _this.tipagedata = res.data;
              _this.successup();
            } else {
              // console.log("An error occurred while uploading the file.");
            }
          };
          xhr.send(formData);
        },
        error(err) {
          //错误处理
          console.log(err.message);
        },
      });
    },

    //获取图片id
    getImageId(val) {
      let that = this;
      var img = new Image();
      img.src = val;
      img.crossOrigin = "Anonymous"; // 如果图片需要跨域访问，可以设置这个属性
      img.onload = function() {
        let width = img.width;
        let height = img.height;
        if (width > height) {
          if (width > 200) {
            height *= 200 / width;
            width = 200;
          }
        } else {
          if (height > 200) {
            width *= 200 / height;
            height = 200;
          }
        }
        var canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var base64 = canvas.toDataURL("image/png", 0.5); // 可以改为image/jpeg，image/webp等其他格式
        let index = base64.indexOf("base64,");
        let base64String = base64.substr(index + 7, base64.length);
        let datas = {
          url: "/1/com.alibaba.fenxiao.crossborder/product.image.upload",
          uploadImageParam: JSON.stringify({
            imageBase64: base64String,
          }),
        };
        that.$api.uploadImagePic(datas).then((res) => {
          if (res.data.code != 200)
            return that.$message.error(that.$fanyi(res.data.message));
          that.imageId = res.data.result;
          that.successup();
        });
      };
    },

    // 图片上传成功事件
    successup() {
      console.log(this.imageId, this.tipagedata);
      if (this.imageId && this.tipagedata) {
        // this.pageLoading.close();
        this.showUpModel = "box";
        this.$store.commit("getsearchImg", this.tipagedata);

        this.$fun.routerToPage(
          "/CommoditySearch?type=imgSearch&imgUrl=" +
            this.tipagedata +
            "&imageId=" +
            this.imageId
        );
      }
      return;
    },

    oninputMe(e) {
      if (!e.srcElement.value) {
        this.showPlaceHold = true;
      } else {
        this.showPlaceHold = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.searchBgBox {
  background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202408/66d2a9ed24f4d.svg");
  background-repeat: no-repeat;
  background-size: 150% 560px;
  background-position: 50% 0px;
  .upLoadShadow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 40;
  }
  .searchBox {
    margin: 0 auto;
    width: 770px;
    padding: 88px 10px 80px;

    h3 {
      color: #1a73e8;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      line-height: 30px; /* 150% */
      margin-bottom: 8px;
    }
    h1 {
      padding: 0 20px;
      margin-bottom: 40px;
      color: var(--Gray-950, #0c111d);
      text-align: center;
      font-size: 48px;
      font-weight: 600;
      line-height: 60px; /* 125% */
      letter-spacing: -0.96px;
    }
    .upImg {
      margin-bottom: 24px;

      .upCon {
        transition: 0.4s;
      }
      .upCon.bgBlueBtn {
        width: 565px;
        height: 65px;
      }
      .upCon.upLoadBox {
        height: 230px;
        border-radius: 16px;
        border: 2px solid var(--Gray-200, #eaecf0);
        background: #fff;
        width: 750px;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
          0px 2px 4px -2px rgba(16, 24, 40, 0.06);

        span {
          color: var(--Gray-700, #344054);
          font-size: 18px;
        }
        .blue {
          color: var(--Rakumart-500, #1a73e8);
        }
        .closeBtn {
          position: absolute;
          top: 12px;
          right: 12px;
          padding: 10px;
        }
      }
      .upIcon {
        margin-right: 10px;
      }
      .upIconBox {
        margin-right: 10px;
        position: relative;
        transition: 0.3s;
        img {
          transition: 0.3s;
        }
        .blueImg {
          transform-origin: bottom;
        }
        .upIconShow {
          position: absolute;
          left: -10px;
          top: 15px;
          opacity: 0;
          rotate: 30deg;
          transform-origin: bottom;
        }
      }

      /deep/.el-upload-dragger {
        background-color: transparent;
        border: unset;
        border-radius: unset;
        width: unset;
        height: unset;
      }

      .fileHoverBox {
        display: none;
        font-size: 18px;
        line-height: 28px; /* 155.556% */
        color: var(--Gray-700, #344054);
      }

      .fileLoadingBox {
        width: 437px;
        gap: 10px;

        .loadingIcon {
          width: 48px;
          height: 48px;
          /deep/.el-loading-spinner .path {
            stroke: #1a73e8;
          }
        }
        .tipBox {
          color: var(--Gray-950, #0c111d);
          text-align: center;
          font-size: 18px;
          line-height: 28px; /* 155.556% */
        }
        .smallTipBox {
          color: var(--Gray-600, #475467);
          font-size: 14px;
          line-height: 20px; /* 142.857% */
        }
      }
      // 文件拖拽样式
      /deep/.el-upload-dragger.is-dragover {
        .upCon {
          background: var(--Rakumart-50, #eef5fe);
          box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
            0px 2px 4px -2px rgba(16, 24, 40, 0.06);
          border: 2px solid var(--Gray-200, #eaecf0);

          .fileHoverBox {
            display: block;
          }
          .fileNormalBox {
            display: none;
          }
        }
      }
    }
    .upImg.readOnly {
      position: relative;
      z-index: 41;
    }

    .upImg:hover {
      .upIconBox {
        margin-right: 20px;
        .blueImg {
          rotate: 30deg;
        }
        .upIconShow {
          opacity: 1;
          rotate: 0deg;
        }
      }
    }

    .textSearchBox {
      font-size: 16px;
      .searchTitle {
        color: var(--Gray-950, #0c111d);
        text-align: center;
        font-size: 18px;
        line-height: 28px; /* 155.556% */
        margin-bottom: 24px;
      }
      .inputBox {
        width: 400px;
        height: 45px;
        border-radius: 8px;
        border: 1px solid var(--Gray-100, #f2f4f7);
        background: #fff;
        box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
          0px 2px 4px -2px rgba(16, 24, 40, 0.06);
        padding: 0 16px;
        position: relative;

        .iconBox {
          margin-right: 16px;
        }
        input {
          flex: 1;
          height: 45px;
          border: none;
          background-color: transparent;
          position: relative;
          font-size: 16px;
          z-index: 1;
        }
        .placeholderBox {
          position: absolute;
          left: 56px;
        }
      }
      .searchBtn {
        width: 45px;
        height: 45px;
        margin-left: 16px;
        transition: 0.3s;
        span {
          display: block;
          width: 0;
          overflow: hidden;
          font-size: 16px;
        }

        &:hover {
          width: 180px;
          span {
            width: 60px;
          }
        }
      }

      .union {
        text-align: center;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
</style>
